import React from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { document } from "browser-monads";

// Styles
import * as styles from "../../styles/components/Header.module.css";

// Assets
import Logo from "../../images/icons/sandbox-logo.svg";
import HamburgerMenu from "../../images/icons/hamburger-menu.svg";
import CloseIcon from "../../images/icons/close.svg";

// Hooks
import useWindowSize from "../../hooks/useWindowSize";

//Header Components
import {
  MotionLink,
  isActive,
  itemVariants,
  sideVariants,
  staggerDuration,
} from "../../components/Header";

const MobileHeader = () => {
  const [open, cycleOpen] = useCycle(false, true);
  const { t } = useI18next();
  const { height } = useWindowSize();

  React.useEffect(() => {
    const targetElement = document.querySelector("body");
    if (open) {
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }
    return () => clearAllBodyScrollLocks();
  }, [open]);

  const links = [
    {
      name: t("header.links.login"),
      //redirect to login in the project
      link: "/login",
    },
  ];

  return (
    <div className={styles.mobile}>
      <button onClick={cycleOpen} className={styles.mobile__hamburger}>
        <HamburgerMenu />
      </button>
      <AnimatePresence>
        {open && (
          <motion.aside
            initial={{ height: 0 }}
            animate={{
              height: height,
            }}
            exit={{
              height: 0,
              transition: {
                delay: (links.length * staggerDuration) / 2,
                duration: 0.3,
              },
            }}
            className={styles.mobile__links__container}
          >
            <div className={styles.mobile__menu__header}>
              <span>{t("header.links.mobile.title")}</span>
              <button onClick={cycleOpen}>
                <CloseIcon />
              </button>
            </div>
            <motion.div
              className={styles.mobile__links}
              initial="closed"
              animate="open"
              exit="closed"
              variants={sideVariants}
            >
              {links.map((link) =>
                link.url ? (
                  <motion.a
                    key={link.url}
                    href={link.url}
                    whileHover={{ scale: 1.1 }}
                    variants={itemVariants}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.name}
                  </motion.a>
                ) : (
                  ""
                )
              )}
            </motion.div>
            <MotionLink
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  delay: links.length * staggerDuration,
                  duration: 0.3,
                },
              }}
              exit={{ opacity: 0 }}
              to="/sandbox/signup"
              className={`button button--secondary ${styles.button__mobile}`}
            >
              {t("header.links.get_started")}
            </MotionLink>
          </motion.aside>
        )}
      </AnimatePresence>
    </div>
  );
};

const HeaderMenuItem = ({ link }) => {
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      onMouseEnter={() => (link.sublinks ? setIsMouseOver(true) : null)}
      onMouseLeave={() => (link.sublinks ? setIsMouseOver(false) : null)}
      onFocus={() => (link.sublinks ? setIsMouseOver(true) : null)}
      onBlur={() => (link.sublinks ? setIsMouseOver(false) : null)}
    >
      {link.url ? (
        <a href={link.url} target="_blank" rel="noopener noreferrer">
          {link.name}
        </a>
      ) : link.link ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url
        <Link
          to={!link?.isOuter ? link.link : "/sandbox"}
          getProps={isActive}
          onClick={
            link?.isOuter ? () => window.open(link.link, "_blank") : null
          }
        >
          {link.name}
        </Link>
      ) : (
        <a href="#" onClick={(e) => e.preventDefault()}>
          {link.name}
        </a>
      )}
    </li>
  );
};

const SandboxHeader = ({ isOnlyWhite }) => {
  const { language, t } = useI18next();
  const [isScrolled, setIsScrolled] = React.useState(false);
  // Add event listener for page scroll
  React.useLayoutEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const links = [
    {
      name: t("header.links.login"),
      //redirect to login in the project
      link: "https://sandbox.zealpay.app/signin?callbackUrl=/home",
      isOuter: true,
    },
  ];
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: language,
          dir: language === "ar" ? "rtl" : "ltr",
        }}
      />
      <header
        className={`${styles.wrapper} ${
          isScrolled || isOnlyWhite
            ? styles.wrapper__default
            : styles.wrapper__transparent
        }`}
      >
        <div className={`${styles.content} sectionContainer`}>
          <div className={styles.sandbox__logo}>
            <Link to="/sandbox">
              <Logo />
            </Link>
          </div>
          <div className={styles.links}>
            <ul>
              {links.map((link) => (
                <HeaderMenuItem key={link.url} link={link} />
              ))}
            </ul>
            <Link
              to="/sandbox/signup"
              className={`button button--primary ${styles.button}`}
            >
              {t("header.links.get_started")}
            </Link>
          </div>

          <MobileHeader />
        </div>
      </header>
    </>
  );
};

SandboxHeader.propTypes = {
  siteTitle: PropTypes.string,
};

SandboxHeader.defaultProps = {
  siteTitle: ``,
};

export default SandboxHeader;
